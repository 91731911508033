import { LinkViewModel, makeLinkViewModel } from '../../links/api/link'
import { TreeGridColors } from '../../treegrid/types'
import { MaintainerTypeEnum, Status } from '../../types/common'
import { ProjectResponse } from './project-response'

export const makeProjectViewModel = (project: ProjectResponse): ProjectViewModel => {
  const projectLinks = (project.links || []).map(makeLinkViewModel)

  return {
    ...project,
    shownProjectNumber: project.customProjectNumber || project.projectNumber,
    ganttBarColor: project.ganttBarColor || 'Default',
    isManager: (id) => project.managers.map(({ id }) => id).includes(id),
    isParticipant: (id) => project.participants.map(({ id }) => id).includes(id),
    isCustomer: (id) => project.customers.map(({ id }) => id).includes(id),
    isSupplier: (id) => project.suppliers.map(({ id }) => id).includes(id),
    isMaintainer: (id) => project.maintainerId === id,
    isUserProject: project.maintainerType === MaintainerTypeEnum.USER,
    isOrgProject: project.maintainerType === MaintainerTypeEnum.ORG,
    isCompleted: project.status === Status.COMPLETED,
    isNotStarted: project.status === Status.NOT_STARTED,
    isInProgress: project.status === Status.IN_PROGRESS,
    isRejected: project.status === Status.REJECTED,
    links: projectLinks,
  }
}

export type ProjectViewModel = Omit<ProjectResponse, 'links'> & {
  shownProjectNumber: string
  ganttBarColor: TreeGridColors
  isUserProject: boolean
  isOrgProject: boolean
  isManager: (membershipId: string) => boolean
  isParticipant: (membershipId: string) => boolean
  isCustomer: (customerId: string) => boolean
  isSupplier: (supplierId: string) => boolean
  isMaintainer: (maintainerId: string) => boolean
  isInProgress: boolean
  isCompleted: boolean
  isRejected: boolean
  isNotStarted: boolean
  links: LinkViewModel[]
}
