import { TimeZoneType } from '../../../constants/timezones'
import { IOptionNormalized } from '../../../options/interfaces/options-normalized'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getWorkspaceOptions,
} from '../../../options/utils'
import { DateTimeService } from '../../../services/date-time-service'
import { getColorHtmlForColor, makeEnumString, mapColorToTranslations } from '../../utils'
import { TodoActivityRow } from '../types'
import { isTodoDueDatePastDue } from '../../../todos/utils/is-past-due'
import { TodoViewModel } from '../../../todos/api/todo'
import { getTitleHtmlPrefix } from '../../utils/tree-grid'
import { TreeGridTranslations } from '../../hooks/use-translations'

export const makeTodoActivityRow = ({
  todo,
  dateFormat,
  timeZone,
  options,
  translations,
}: MakeTodoActivityRowProps): TodoActivityRow => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(todo)

  const { id, title, description, enableTimeComponent, projectLink, ancestorTaskLinks, taskLink, project, task } = todo
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const activityNumber = task?.taskNumber || project?.projectNumber || ''
  const customers = todo.customers.map((c) => c.id).join(';')
  const workspaces = todo.workspaces.map((w) => w.id).join(';')
  const responsible = todo.responsible.map((r) => r.id).join(';')
  const ganttGanttHtmlRight = getGanttGanttHtmlRight()
  const GanttGanttHtmlRight = '*ganttGanttHtmlRight*'
  const translatedColor = mapColorToTranslations(translations)[todo.ganttBarColor]
  const ganttBarColor = getColorHtmlForColor({ color: todo.ganttBarColor, translatedColor })
  const GanttGanttClass = todo.ganttBarColor === 'Default' ? 'Blue' : todo.ganttBarColor

  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const deactivatedCustomers = todo.customers.filter(({ id }) => !customerIds.includes(id))
  const deactivatedCustomersNames = deactivatedCustomers.map(({ name }) => name)
  const deactivatedCustomersIds = deactivatedCustomers.map(({ id }) => id)
  const customersEnum = makeEnumString([...customerNames, ...deactivatedCustomersNames])
  const customersEnumKeys = makeEnumString([...customerIds, ...deactivatedCustomersIds])

  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const deactivatedWorkspaces = todo.workspaces.filter(({ id }) => !workspaceIds.includes(id))
  const deactivatedWorkspacesNames = deactivatedWorkspaces.map(({ name }) => name)
  const deactivatedWorkspacesIds = deactivatedWorkspaces.map(({ id }) => id)
  const workspacesEnum = makeEnumString([...workspaceNames, ...deactivatedWorkspacesNames])
  const workspacesEnumKeys = makeEnumString([...workspaceIds, ...deactivatedWorkspacesIds])

  const responsibleOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const responsibleNames = responsibleOptions.map(getOptionName)
  const responsibleIds = responsibleOptions.map(getOptionId)
  const deactivatedResponsible = todo.responsible.filter(({ id }) => !responsibleIds.includes(id))
  const deactivatedResponsibleNames = deactivatedResponsible.map(({ name }) => name)
  const deactivatedResponsibleIds = deactivatedResponsible.map(({ id }) => id)
  const responsibleEnum = makeEnumString([...responsibleNames, ...deactivatedResponsibleNames])
  const responsibleEnumKeys = makeEnumString([...responsibleIds, ...deactivatedResponsibleIds])

  return {
    id,
    type: 'todo',
    titleHtmlPrefix: getTitleHtmlPrefix({ projectLink, taskLink, ancestorTaskLinks }),
    title,
    activityNumber,
    description,
    enableTimeComponent,
    GanttGanttHtmlRight,
    ganttGanttHtmlRight,
    ganttBarColor,
    ganttBarColorFilterValue: translatedColor,
    GanttGanttClass,
    plannedEndDate: getPlannedEndDate(),
    plannedEndDateClass: getPlannedEndDateClass(),
    status: todo.completed ? 1 : 0,
    statusType: 'Bool',
    customers,
    customersEnum,
    customersEnumKeys,
    responsible,
    responsibleEnum,
    responsibleEnumKeys,
    workspaces,
    workspacesEnum,
    workspacesEnumKeys,
    links: null,
    activityNumberCanEdit: 0,
    titleCanEdit: todo.canUpdate ? 1 : 0,
    descriptionCanEdit: todo.canUpdate ? 1 : 0,
    plannedStartDateCanEdit: 0,
    plannedEndDateCanEdit: todo.canUpdate ? 1 : 0,
    actualStartDateCanEdit: 0,
    statusCanEdit: todo.canUpdate ? 1 : 0,
    stateCanEdit: 0,
    statusDescriptionCanEdit: 0,
    customersCanEdit: todo.canUpdate && todo.organisation ? 1 : 0,
    responsibleCanEdit: todo.canUpdate && todo.organisation ? 1 : 0,
    participantsCanEdit: 0,
    suppliersCanEdit: 0,
    workspacesCanEdit: todo.canUpdate && todo.organisation ? 1 : 0,
    ganttBarColorCanEdit: todo.canUpdate ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getPlannedEndDate(): number | string {
    return todo.dueDate ? dateTimeService.removeTimezoneOffset(todo.dueDate, 'UTC').getTime() : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isTodoDueDatePastDue({ todo, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getGanttGanttHtmlRight(): string {
    let text = `${todo.title}`
    const hasTitleAndDueDate = Boolean(text) && todo.dueDate
    if (hasTitleAndDueDate) text += ` :`
    if (todo.dueDate) {
      const date = new Date(todo.dueDate)
      const formattedDate = dateTimeService.format(date, dateTimeService.getDateFormat())
      text += ` ${formattedDate}`
      if (enableTimeComponent) {
        const time = dateTimeService.getDoubleDigitTime(date)
        text += ` ${time}`
      }
    }
    return text
  }
}

function makeFilterOptionByOrganisation(todo: TodoViewModel) {
  return function (option: IOptionNormalized) {
    return option.orgId === todo.organisation?.id
  }
}

type MakeTodoActivityRowProps = {
  todo: TodoViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}
