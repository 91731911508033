import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import FormikInput from '../components/FormikInput'
import ButtonLink from '../components/ButtonLink'
import ErrorFallback from '../components/ErrorFallback'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Form, Formik, FormikHelpers } from 'formik'
import { useErrorHandler } from 'react-error-boundary'
import { useI18n, useNetworkStatus } from '../hooks'
import { ErrorBoundary } from 'react-error-boundary'
import { Eye, EyeOff } from 'react-feather'
import { useAppDispatch } from '../store'
import { LoginUser } from '../users/store/actions'
import { LoginData } from '../users/auth-api'
import { Alert, AlertTitle } from '@material-ui/lab'
import { paths } from '../paths'
import Turnstile from '../components/Turnstile'
import { useTurnstile } from '../hooks/use-turnstile'
import { COLOR_ERROR } from '../constants'

const LoginRoute = () => {
  const translations = useTranslations()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" style={{ paddingBottom: 24 }}>
            {translations.login}
          </Typography>
        </Grid>
        <Grid item>
          <LoginForm />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <ButtonLink data-test="signup-link" to={paths.signup()}>
                {translations.noAccount}
              </ButtonLink>
            </Grid>
            <Grid item>
              <ButtonLink data-test="forgot-password-link" to={paths.forgotPassword()}>
                {translations.forgotPassword}
              </ButtonLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

const LoginForm = () => {
  const turnstile = useTurnstile()
  const translations = useTranslations()
  const dispatch = useAppDispatch()
  const handleError = useErrorHandler()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 5000 })
  const { setStatus, isRejected, isIdle, isPending } = networkStatus
  const [showPassword, setShowPassword] = React.useState(false)

  const handleLogin = async (values: LoginData, { setSubmitting }: FormikHelpers<LoginData>) => {
    const isTurnstileVerified = await turnstile.verifyToken()
    if (!isTurnstileVerified) return

    try {
      setStatus('pending')
      const actionResult = await dispatch(LoginUser(values))
      const requestStatus = actionResult.meta.requestStatus
      setStatus(requestStatus)
      setSubmitting(false)
    } catch (error: any) {
      handleError(error)
    }
  }

  const formikProps = {
    initialValues: { email: '', password: '' },
    onSubmit: handleLogin,
  }

  const passwordInputEndAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(e) => e.preventDefault()}
      >
        {showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, isValid }) => {
        const shouldDisableInput = isPending()
        const shouldDisableButton = Boolean(!isValid || !isIdle() || !turnstile.isSolved)

        let errorMessage
        let title = translations.error
        if (isRejected()) errorMessage = translations.loginFailedMessage

        return (
          <Form>
            <Grid direction="column" spacing={2} container>
              <Grid item>
                <FormLabel htmlFor="email">{`${translations.email}:`}</FormLabel>
                <FormikInput
                  data-test="email-input"
                  id="email"
                  name="email"
                  placeholder={translations.emailPlaceholder}
                  variant="outlined"
                  disabled={shouldDisableInput}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormLabel htmlFor="password">{`${translations.password}:`}</FormLabel>
                <FormikInput
                  data-test="password-input"
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={translations.passwordPlaceholder}
                  variant="outlined"
                  disabled={shouldDisableInput}
                  InputProps={{ endAdornment: passwordInputEndAdornment }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                {errorMessage && (
                  <Alert severity="error">
                    <AlertTitle>{title}</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
              </Grid>
              <Grid item>
                <p style={{ color: COLOR_ERROR }}>{turnstile.error}</p>
                <Turnstile {...turnstile.props} />
              </Grid>
              <Grid item>
                <Button
                  data-test="submit-button"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={shouldDisableButton}
                  startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                >
                  {isSubmitting ? translations.loggingIn : translations.login}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations) => {
  const { translations: t } = useI18n('translation')

  const {
    email = defaults.email,
    emailPlaceholder = defaults.emailPlaceholder,
    password = defaults.password,
    passwordPlaceholder = defaults.passwordPlaceholder,
    noAccount = defaults.noAccount,
    forgotPassword = defaults.forgotPassword,
    error = defaults.error,
    loginFailedMessage = defaults.loginFailedMessage,
    login = defaults.login,
    loggingIn = defaults.loggingIn,
  } = t

  return {
    email,
    emailPlaceholder,
    password,
    passwordPlaceholder,
    noAccount,
    forgotPassword,
    error,
    loginFailedMessage,
    login,
    loggingIn,
  }
}

const defaultTranslations = {
  email: 'Email',
  emailPlaceholder: 'Enter your email',
  password: 'Password',
  passwordPlaceholder: 'Enter your password',
  noAccount: `I don't have an account`,
  forgotPassword: `I forgot my password`,
  error: 'Error',
  loginFailedMessage: 'Failed to login!',
  login: 'Login',
  loggingIn: 'Logging in',
}
type Translations = typeof defaultTranslations

export default LoginRoute
