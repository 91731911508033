import React from 'react'
import DateWidget from '../../components/DateWidget'
import StatusWidget from '../../components/StatusWidget'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TaskManagersWidget from './TaskManagersWidget'
import TaskParticipantsWidget from './TaskParticipantsWidget'
import TaskSuppliersWidget from './TaskSuppliersWidget'
import TaskWorkspacesWidget from './TaskWorkspacesWidget'
import DescriptionEditor from '../../components/DescriptionEditor'
import TaskStateSelect from './TaskStateSelect'
import { useTask } from '../hooks/use-task'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { makeStyles, Theme } from '@material-ui/core'
import { ArrowRight } from 'react-feather'
import { StatusDescriptionHistoryDialog } from '../../components/StatusDescriptionHistory'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { useTaskMutations } from '../hooks/use-task-mutations'
import { useUnarchiveTask } from '../hooks/use-unarchive-task'
import { useUpdateTaskStatus } from '../hooks/use-update-task-status'
import ColorSelectionWidget from '../../components/ColorSelectionWidget'
import CreationMetaDataWidget from '../../components/CreationMetaDataWidget'
import LinkList from '../../links/components/LinkList'

const TaskDetails = ({ taskId, boardId }: TaskDetailsProps) => {
  const classes = useStyles()
  const taskActions = useTaskMutations()
  const { unarchiveTask } = useUnarchiveTask()
  const { updateTaskStatus } = useUpdateTaskStatus()
  const translations = useTranslations(defaultTranslations)
  const [isHistoryOpen, setHistoryOpen] = React.useState(false)

  const openHistory = () => setHistoryOpen(true)
  const closeHistory = () => setHistoryOpen(false)

  const {
    task,
    formattedPlannedEnd,
    formattedPlannedStart,
    formattedActualEnd,
    formattedActualStart,
    plannedStartDateIsPastDue,
    plannedEndDateIsPastDue,
    currentStatusDescription,
    sortedStatusDescriptionHistory,
  } = useTask(taskId)
  const { supplierOptions, workspaceOptions } = useOrgOptions(task?.maintainerId || '')

  if (!task) return null

  const { plannedStartDate, plannedEndDate, actualStartDate, actualEndDate } = task
  const showSupplierSection = Boolean(supplierOptions.length)
  const showWorkspaceSection = Boolean(workspaceOptions.length)

  return (
    <div data-test="task-details-card">
      {task.archived && (
        <div className={classes.archivedMessageBar}>
          <Typography variant="h4">{translations.archivedMessage}</Typography>
          {task.canDelete && task.archived && (
            <Button
              onClick={() => unarchiveTask({ taskId: task.id, boardId })}
              variant="contained"
              color="primary"
              style={{ marginBottom: 8 }}
            >
              {translations.unarchiveButtonLabel}
            </Button>
          )}
        </div>
      )}
      <Grid container spacing={4} className={classes.container} wrap="nowrap">
        {task.archived && <div className={classes.readOnlyOverlay}></div>}
        <Grid item className={classes.main}>
          <DescriptionEditor
            description={task.description || ''}
            onSaveDescription={(description) => taskActions.updateInfo(task.id, { description })}
            TextAreaAutosizeProps={{
              placeholder: task.canUpdateDetails
                ? translations.descriptionPlaceholder
                : translations.readOnlyDescriptionPlaceholder,
              disabled: !task.canUpdateDetails,
            }}
          />
          <div style={{ marginBottom: 24, marginTop: 24 }}>
            <Typography component="h4" variant="body1">
              <strong>{translations.taskStateLabel}</strong>
            </Typography>
            <TaskStateSelect
              state={task.state}
              onStateChange={(state) => taskActions.updateStatusDescription(task.id, { state })}
              disabled={!task.canUpdateDetails}
            />
          </div>
          <div style={{ marginBottom: 24 }}>
            <Typography component="h4" variant="body1" style={{ marginBottom: 8 }}>
              <strong>{translations.statusDescriptionLabel}</strong>
            </Typography>
            <DescriptionEditor
              description={task.statusDescription || ''}
              onSaveDescription={(statusDescription) =>
                taskActions.updateStatusDescription(task.id, { statusDescription })
              }
              TextAreaAutosizeProps={{
                placeholder: task.canUpdateStatus
                  ? translations.statusDescriptionPlaceholder
                  : translations.readOnlyStatusDescriptionPlaceholder,
                disabled: !task.canUpdateStatus,
              }}
            />
            {currentStatusDescription && (
              <>
                <Typography variant="caption" style={{ marginTop: -8 }}>
                  {currentStatusDescription.updatedBy}
                  &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                  {currentStatusDescription.updatedAt}
                  &nbsp;&nbsp;&nbsp;•
                  <Button
                    color="primary"
                    size="small"
                    className={classes.viewHistoryButton}
                    onClick={openHistory}
                    endIcon={<ArrowRight size={12} />}
                  >
                    {translations.viewHistoryButtonLabel}
                  </Button>
                </Typography>
                <StatusDescriptionHistoryDialog
                  isOpen={isHistoryOpen}
                  onClose={closeHistory}
                  entries={sortedStatusDescriptionHistory}
                  title={translations.statusDescriptionHistoryDialogTitle}
                />
              </>
            )}
          </div>
          {task.isOrgTask && (
            <>
              {(showSupplierSection || showWorkspaceSection) && (
                <>
                  <Grid container spacing={3}>
                    {showSupplierSection && (
                      <Grid item style={{ flex: 1 }}>
                        <TaskSuppliersWidget task={task} boardId={boardId} />
                      </Grid>
                    )}
                    {showWorkspaceSection && (
                      <Grid item style={{ flex: 1 }}>
                        <TaskWorkspacesWidget task={task} boardId={boardId} />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </>
          )}
          {task.isOrgTask && (
            <>
              <Grid container spacing={3}>
                <Grid item style={{ flex: 1 }}>
                  <TaskManagersWidget task={task} boardId={boardId} />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <TaskParticipantsWidget task={task} boardId={boardId} />
                </Grid>
              </Grid>
            </>
          )}
          <section style={{ margin: '24px 0px 24px 0px' }}>
            <ColorSelectionWidget
              selectedColor={task.ganttBarColor}
              onColorChange={(color) => taskActions.updateInfo(task.id, { ganttBarColor: color })}
              disabled={!task.canUpdateDetails}
            />
          </section>

          <Grid container spacing={3} style={{ margin: '24px 0px 24px 0px' }}>
            <Grid item style={{ flex: 1 }} data-test="link-list">
              <LinkList
                entityId={task.id}
                entityType="task"
                canAddLink={task.canUpdateDetails}
                canUpdateLinks={task.canUpdateDetails}
                canDeleteLinks={task.canUpdateDetails}
              />
            </Grid>
            <Grid item style={{ flex: 1 }} />
          </Grid>
        </Grid>
        <Grid item className={classes.sidebar}>
          <section className={classes.sidebarWidget}>
            <DateWidget
              formattedDate={formattedPlannedStart}
              isoDate={plannedStartDate || null}
              onDateChange={(plannedStartDate) =>
                taskActions.updatePlan(task.id, { plannedStartDate: plannedStartDate?.toISOString() })
              }
              isReadOnly={!task.canUpdatePlan}
              label={translations.planStartWidgetLabel}
              emptyDateMessage={translations.planStartEmptyMessage}
              clearButtonHint={translations.planStartClearHint}
              isClearable
              showTimeSelect={task.enableTimeComponent}
              isPastDue={plannedStartDateIsPastDue}
            />
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <DateWidget
              formattedDate={formattedPlannedEnd}
              isoDate={plannedEndDate || null}
              onDateChange={(plannedEndDate) =>
                taskActions.updatePlan(task.id, { plannedEndDate: plannedEndDate?.toISOString() })
              }
              isReadOnly={!task.canUpdatePlan}
              label={translations.planEndWidgetLabel}
              emptyDateMessage={translations.planEndEmptyMessage}
              clearButtonHint={translations.planEndClearHint}
              isClearable
              showTimeSelect={task.enableTimeComponent}
              isPastDue={plannedEndDateIsPastDue}
            />
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <StatusWidget
              isReadOnly={!task.canUpdateStatus}
              status={task.status}
              label={translations.statusWidgetLabel}
              onStatusChange={(status) => updateTaskStatus(task.id, { status, boardId })}
            />
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <DateWidget
              formattedDate={formattedActualStart}
              isoDate={actualStartDate || null}
              onDateChange={(actualStartDate) =>
                updateTaskStatus(task.id, { actualStartDate: actualStartDate?.toISOString(), boardId })
              }
              isReadOnly={!task.canUpdateStatus}
              label={translations.actualStartWidgetLabel}
              emptyDateMessage={translations.actualStartEmptyMessage}
              clearButtonHint={translations.actualStartClearHint}
              isClearable
              showTimeSelect={task.enableTimeComponent}
            />
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <DateWidget
              formattedDate={formattedActualEnd}
              isoDate={actualEndDate}
              onDateChange={(actualEndDate) =>
                updateTaskStatus(task.id, { actualEndDate: actualEndDate?.toISOString(), boardId })
              }
              isReadOnly={!task.canUpdateStatus}
              label={translations.actualEndWidgetLabel}
              emptyDateMessage={translations.actualEndEmptyMessage}
              clearButtonHint={translations.actualEndClearHint}
              isClearable
            />
          </section>
          <Divider />

          {task.canDelete && !task.archived && (
            <Button
              onClick={() => taskActions.archiveTask({ taskId, boardId })}
              variant="outlined"
              style={{ marginBottom: 8, marginTop: 16 }}
              fullWidth
            >
              {translations.archiveButtonLabel}
            </Button>
          )}

          <section className={classes.sidebarWidget}>
            <CreationMetaDataWidget creatorName={task.creatorFullname} creationDate={task.createdAt} />
          </section>
        </Grid>
      </Grid>
    </div>
  )
}

const useTranslations = (defaults: TaskDetailsTranslations): TaskDetailsTranslations => {
  const { translations: t } = useI18n('task')
  const { translations: treegridTranslations } = useI18n('treegrid')
  const translations = (t?.taskDetails || {}) as StringMap
  const { statusColumnHeader = defaults.statusWidgetLabel, stateColumnHeader = defaults.taskStateLabel } =
    treegridTranslations
  const statusWidgetLabel = statusColumnHeader
  const taskStateLabel = stateColumnHeader

  const {
    archivedMessage = defaults.archivedMessage,
    unarchiveButtonLabel = defaults.unarchiveButtonLabel,
    resourcesSectionHeader = defaults.resourcesSectionHeader,
    relationsSectionHeader = defaults.relationsSectionHeader,
    statusSectionHeader = defaults.statusSectionHeader,
    planStartWidgetLabel = defaults.planStartWidgetLabel,
    planStartEmptyMessage = defaults.planStartEmptyMessage,
    planStartClearHint = defaults.planStartClearHint,
    planEndWidgetLabel = defaults.planEndWidgetLabel,
    planEndEmptyMessage = defaults.planEndEmptyMessage,
    planEndClearHint = defaults.planEndClearHint,
    actualStartWidgetLabel = defaults.actualStartWidgetLabel,
    actualStartEmptyMessage = defaults.actualStartEmptyMessage,
    actualStartClearHint = defaults.actualStartClearHint,
    actualEndWidgetLabel = defaults.actualEndWidgetLabel,
    actualEndEmptyMessage = defaults.actualEndEmptyMessage,
    actualEndClearHint = defaults.actualEndClearHint,
    actionsSectionHeader = defaults.actionsSectionHeader,
    archiveButtonLabel = defaults.archiveButtonLabel,
    descriptionPlaceholder = defaults.descriptionPlaceholder,
    statusDescriptionPlaceholder = defaults.statusDescriptionPlaceholder,
    readOnlyDescriptionPlaceholder = defaults.readOnlyDescriptionPlaceholder,
    readOnlyStatusDescriptionPlaceholder = defaults.readOnlyStatusDescriptionPlaceholder,
    viewHistoryButtonLabel = defaults.viewHistoryButtonLabel,
    statusDescriptionLabel = defaults.statusDescriptionLabel,
    statusDescriptionHistoryDialogTitle = defaults.statusDescriptionHistoryDialogTitle,
  } = translations

  return {
    archivedMessage,
    unarchiveButtonLabel,
    resourcesSectionHeader,
    relationsSectionHeader,
    statusSectionHeader,
    planStartWidgetLabel,
    planStartEmptyMessage,
    planStartClearHint,
    planEndWidgetLabel,
    planEndEmptyMessage,
    planEndClearHint,
    actualStartWidgetLabel,
    actualStartEmptyMessage,
    actualStartClearHint,
    actualEndWidgetLabel,
    actualEndEmptyMessage,
    actualEndClearHint,
    statusWidgetLabel,
    actionsSectionHeader,
    archiveButtonLabel,
    taskStateLabel,
    descriptionPlaceholder,
    statusDescriptionPlaceholder,
    readOnlyDescriptionPlaceholder,
    readOnlyStatusDescriptionPlaceholder,
    viewHistoryButtonLabel,
    statusDescriptionLabel,
    statusDescriptionHistoryDialogTitle,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: { position: 'relative' },
  readOnlyOverlay: {
    backgroundColor: `${theme.palette.common.black}07`,
    position: 'absolute',
    top: 8,
    bottom: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(-1),
    zIndex: 99,
    cursor: 'not-allowed',
  },
  archivedMessageBar: {
    marginBottom: theme.spacing(),
    padding: theme.spacing(),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: theme.palette.error.main,
  },
  sidebar: { minWidth: 300 },
  main: { flexGrow: 1 },
  sidebarWidget: {
    padding: theme.spacing(1.5, 0),
    '&:first-child': {
      marginTop: -theme.spacing(2.5),
    },
  },
  viewHistoryButton: {
    marginLeft: 4,
    fontSize: 12,
    fontWeight: 400,
    '& .MuiButton-endIcon': {
      marginLeft: 4,
      marginTop: 2,
    },
  },
}))

const defaultTranslations = {
  archivedMessage: 'Task is archived',
  unarchiveButtonLabel: 'Unarchive this task',
  resourcesSectionHeader: 'Task resources',
  relationsSectionHeader: 'Task relations',
  statusSectionHeader: 'Task schedule and status',
  planStartWidgetLabel: 'Planned start date',
  planStartEmptyMessage: 'Planned start date is not given',
  planStartClearHint: 'Clear planned start date',
  planEndWidgetLabel: 'Planned end date',
  planEndEmptyMessage: 'Planned end date is not given',
  planEndClearHint: 'Clear planned end date',
  actualStartWidgetLabel: 'Actual start date',
  actualStartEmptyMessage: 'Actual start date is not given',
  actualStartClearHint: 'Clear actual start date',
  actualEndWidgetLabel: 'Actual end date',
  actualEndEmptyMessage: 'Actual end date is not given',
  actualEndClearHint: 'Clear actual end date',
  statusWidgetLabel: 'Status',
  actionsSectionHeader: 'Task actions',
  archiveButtonLabel: 'Archive this task',
  taskStateLabel: 'State',
  descriptionPlaceholder: 'Add a description',
  statusDescriptionPlaceholder: 'Add a short status description',
  readOnlyDescriptionPlaceholder: 'This task has no description',
  readOnlyStatusDescriptionPlaceholder: 'This task has no status description',
  viewHistoryButtonLabel: 'View history',
  statusDescriptionLabel: 'Status description',
  statusDescriptionHistoryDialogTitle: 'Status description history',
}

type TaskDetailsProps = { taskId: string; boardId?: string }
type TaskDetailsTranslations = typeof defaultTranslations
export default TaskDetails
