import { RouteComponentProps } from 'react-router-dom'
import { useProject } from '../../../projects/hooks/use-project'
import { useGetTasksQuery } from '../../../tasks/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProjectArchivedMessageBar from '../../../projects/components/ProjectArchivedMessageBar'
import TaskTreeGrid from '../../../treegrid/task-tree-grid/TaskTreeGrid'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'
import ErrorList from '../../../components/ErrorList'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectTasksRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const projectId = match.params.projectId
  const {
    project,
    bulkAddTasks,
    isLoading: isLoadingProject,
    isError: isErrorProject,
  } = useProject(projectId, queryOptions)
  const tasksQuery = { project: projectId, archived: 'false', links: 'true' } as const
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetTasksQuery(tasksQuery, queryOptions)

  if (isLoadingProject || isLoadingTasks) return <CircularProgress />
  if (isErrorProject) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  if (isErrorTasks) return <ErrorList errors={[translations.fetchErrorTasks]} />

  return (
    <>
      <ProjectArchivedMessageBar project={project} isFullWidth />
      <TaskTreeGrid key={projectId} project={project} tasks={tasks} onAddTask={bulkAddTasks} />
    </>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translations')
  const translations = t || ({} as StringMap)

  const {
    projectNotFoundError = defaults.projectNotFoundError,
    fetchErrorProject = defaults.fetchErrorProject,
    fetchErrorTasks = defaults.fetchErrorTasks,
  } = translations

  return {
    projectNotFoundError,
    fetchErrorProject,
    fetchErrorTasks,
  }
}
const defaultTranslations = {
  projectNotFoundError: 'Project not found',
  fetchErrorProject: 'Failed to fetch project',
  fetchErrorTasks: 'Failed to fetch tasks',
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectTasksRoute
