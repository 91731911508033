import { z } from 'zod'

const LinkEntitySchema = z.enum(['task', 'project'])
export const UrlSchema = z
  .string()
  .url()
  .refine((url) => url.startsWith('http') || url.startsWith('https'), {
    message: 'URL must start with http or https',
  })
  .refine((url) => {
    const hostname = url.split('://')[1] || ''
    const [domain, tld] = hostname.split('.')
    return domain?.length > 0 && tld?.length > 0
  })

export const LinkResponseSchema = z.object({
  id: z.string(),
  entityId: z.string(),
  entityType: LinkEntitySchema,
  url: UrlSchema,
  title: z.string().min(1).max(100),
})

export type LinkResponse = z.infer<typeof LinkResponseSchema>
