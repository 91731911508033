import { addDays, format, previousDay } from 'date-fns'
import { TimeZoneType } from '../../constants/timezones'
import {
  getCustomerOptions,
  getMembershipOptions,
  getSupplierOptions,
  getWorkspaceOptions,
  getOptionId,
  getOptionName,
} from '../../options/utils'
import { DateTimeService } from '../../services/date-time-service'
import { TreeGridTranslations } from '../hooks/use-translations'
import { makeZooms } from '../task-tree-grid/zooms'
import { Option, TreeGridColumn } from '../types'
import {
  makeColorEnum,
  makeEnumString,
  makeStateEnum,
  makeStatusEnum,
  treegridDateFormat,
  treegridDateTimeFormat,
} from '../utils'
import { ActivityColumnName } from './types'
import { forEach } from 'lodash'

export const makeColumns = ({
  options,
  defaultVisibleColumns = getDefaultVisibleColumns(),
  defaultColumnOrder = getDefaultColumnOrder(),
  translations,
  showGantt,
  timeZone,
  dateFormat,
  weekendDays,
}: MakeColumnProps): { columns: TreeGridColumn[]; rightColumns: TreeGridColumn[] } => {
  const statusEnum = makeStatusEnum(translations)
  const stateEnum = makeStateEnum(translations)

  const customerOptions = getCustomerOptions(options)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const membershipOptions = getMembershipOptions(options)
  const membershipNames = membershipOptions.map(getOptionName)
  const membershipIds = membershipOptions.map(getOptionId)
  const membershipEnum = makeEnumString(membershipNames)
  const membershipEnumKeys = makeEnumString(membershipIds)

  const supplierOptions = getSupplierOptions(options)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const suppliersEnum = makeEnumString(supplierNames)
  const suppliersEnumKeys = makeEnumString(supplierIds)

  const workspaceOptions = getWorkspaceOptions(options)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const ganttBarColorEnum = makeColorEnum(translations)

  const hasCustomerOptions = customerOptions.length > 0
  const hasMembershipOptions = membershipOptions.length > 0
  const hasSupplierOptions = supplierOptions.length > 0
  const hasWorkspaceOptions = workspaceOptions.length > 0
  const isCustomerColumnVisible = hasCustomerOptions && defaultVisibleColumns.includes('customers')
  const isResponsibleColumnVisible = hasMembershipOptions && defaultVisibleColumns.includes('responsible')
  const isParticipantsColumnVisible = hasMembershipOptions && defaultVisibleColumns.includes('participants')
  const isSupplierColumnVisible = hasSupplierOptions && defaultVisibleColumns.includes('suppliers')
  const isWorkspaceColumnVisible = hasWorkspaceOptions && defaultVisibleColumns.includes('workspaces')

  const dateTimeService = new DateTimeService({
    timeZone,
    dateFormat,
  })
  const dateTimeFormat = dateTimeService.getFormat()
  const today = new Date()
  const { zoomList, defaultZoom } = makeZooms({ translations, enableTimeComponent: false })

  const openColumn = {
    Name: 'open',
    Type: 'Icon',
    Visible: defaultVisibleColumns.includes('open'),
    Width: 32,
    CanExport: 0,
    CanPrint: 0,
    CanSort: 0,
    CanHide: 0,
    CanEdit: 0,
    CanMove: 0,
    header: '',
    Class: 'openButton',
  }

  const activityNumberColumn: ActivityListColumn = {
    Name: 'activityNumber',
    MinWidth: 80,
    CaseSensitive: 0,
    header: translations.rowNumberColumnHeader,
    Visible: defaultVisibleColumns.includes('activityNumber'),
  }
  const titleColumn: ActivityListColumn = {
    Name: 'title',
    MinWidth: 160,
    Type: 'Html',
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.titleColumnHeader,
    Visible: defaultVisibleColumns.includes('title'),
  }

  const descriptionColumn: ActivityListColumn = {
    Name: 'description',
    Visible: defaultVisibleColumns.includes('description'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    AcceptEnters: 1,
    header: translations.descriptionColumnHeader,
    CanSort: 0,
  }

  const plannedStartDateColumn: ActivityListColumn = {
    Name: 'plannedStartDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('plannedStartDate'),
    header: translations.plannedStartDateColumnHeader,
  }

  const plannedEndDateColumn: ActivityListColumn = {
    Name: 'plannedEndDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('plannedEndDate'),
    header: translations.plannedEndDateColumnHeader,
  }

  const actualStartDateColumn: ActivityListColumn = {
    Name: 'actualStartDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('actualStartDate'),
    header: translations.actualStartDateColumnHeader,
  }

  const actualBarStartDateColumn = {
    Name: 'actualBarStartDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenActualBarStartDate',
  }

  const actualBarEndDateColumn = {
    Name: 'actualBarEndDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenActualBarEndDate',
  }

  const statusColumn: ActivityListColumn = {
    Name: 'status',
    Type: 'Enum',
    Enum: statusEnum,
    MinWidth: 120,
    Visible: defaultVisibleColumns.includes('status'),
    header: translations.statusColumnHeader,
  }

  const stateColumn: ActivityListColumn = {
    Name: 'state',
    Type: 'Enum',
    Enum: stateEnum,
    CanSort: 0,
    CanExport: 0,
    MinWidth: 64,
    MaxWidth: 64,
    Visible: defaultVisibleColumns.includes('state'),
    header: translations.stateColumnHeader,
  }

  const statusDescriptionColumn: ActivityListColumn = {
    Name: 'statusDescription',
    Visible: defaultVisibleColumns.includes('statusDescription'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    RelWidth: 1,
    AcceptEnters: 1,
    header: translations.statusDescriptionColumnHeader,
    CanSort: 0,
  }

  const customersColumn: ActivityListColumn = {
    Name: 'customers',
    Type: 'Enum',
    Range: true,
    header: translations.customersColumnHeader,
    CanHide: hasCustomerOptions ? 1 : 0,
    Visible: isCustomerColumnVisible,
    Enum: customersEnum,
    EnumKeys: customersEnumKeys,
  }

  const responsibleColumn: ActivityListColumn = {
    Name: 'responsible',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: isResponsibleColumnVisible ? 1 : 0,
    header: translations.responsibleColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const participantsColumn: ActivityListColumn = {
    Name: 'participants',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: isParticipantsColumnVisible ? 1 : 0,
    header: translations.participantsColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const suppliersColumn: ActivityListColumn = {
    Name: 'suppliers',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasSupplierOptions ? 1 : 0,
    Visible: isSupplierColumnVisible ? 1 : 0,
    header: translations.suppliersColumnHeader,
    Enum: suppliersEnum,
    EnumKeys: suppliersEnumKeys,
  }

  const workspacesColumn: ActivityListColumn = {
    Name: 'workspaces',
    Type: 'Enum',
    Range: true,
    MinWidth: 120,
    CanHide: hasWorkspaceOptions ? 1 : 0,
    Visible: isWorkspaceColumnVisible ? 1 : 0,
    header: translations.workspacesColumnHeader,
    Enum: workspacesEnum,
    EnumKeys: workspacesEnumKeys,
  }

  const ganttBarColorColumn = {
    Name: 'ganttBarColor',
    Type: 'Enum',
    CanFilter: 1,
    Enum: ganttBarColorEnum,
    MinWidth: 120,
    Visible: defaultVisibleColumns.includes('ganttBarColor'),
    header: translations.color,
  }

  const ganttGanttHtmlRightColumn = {
    Name: 'ganttGanttHtmlRight',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenGanttGanttHtmlRight',
  }

  const linksColumn = {
    Name: 'links',
    Type: 'Html',
    Visible: defaultVisibleColumns.includes('links'),
    header: translations.linksColumnHeader,
  }

  // Color weekend days differently in the gantt chart
  // If its organisation gantt chart, get the weekend days from the organisation
  // else use the weekend days from the user
  let GanttBackground = `d#${format(today, dateTimeFormat)}#1;`
  if (weekendDays && weekendDays.length) {
    forEach(weekendDays, (day) => {
      const ganttExcludeDay = previousDay(today, day)
      const ganttExcludeStart = format(ganttExcludeDay, treegridDateFormat)
      const ganttExcludeEnd = format(addDays(ganttExcludeDay, 1), treegridDateFormat)
      GanttBackground += `w#${ganttExcludeStart}~${ganttExcludeEnd}#1;` //
    })
  }

  const ganttColumn = {
    Name: 'Gantt',
    Type: 'Gantt',
    MenuName: translations.ganttChartLabel,
    CanHide: 1,
    GanttEdit: '', // Disables editing of the Gantt chart
    GanttCount: 1, // Plan bar and actual bar
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt background configuration ------------ */
    /** ---------------------------------------------------------------- */
    GanttLines: `0#${format(today, treegridDateTimeFormat)}#Navy`, // line indicating today
    GanttBackground, // background lines repeating every day and every weekend
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt zoom configuration ------------------ */
    /** ---------------------------------------------------------------- */
    GanttZoom: defaultZoom,
    GanttZoomList: zoomList,
    GanttSmoothZoom: 1, // controls whether to adjust the GanttWidth of each column with zoom
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt page configuration ------------------ */
    /** ---------------------------------------------------------------- */
    GanttPaging: 1,
    GanttPageWidth: '120%',
    /** ---------------------------------------------------------------- */
    /** -------------------- Plan Bar configuration -------------------- */
    /** ---------------------------------------------------------------- */
    GanttStart: 'plannedStartDate',
    GanttEnd: 'plannedEndDate',
    GanttHeight: 16,
    GanttClass: 'Blue',
  }

  if (showGantt) {
    const GanttNumber = ganttColumn.GanttCount
    Object.assign(ganttColumn, {
      /** Reset gantt count */
      GanttCount: GanttNumber + 1,
      /** ------------------------------------------------------------------ */
      /** -------------------- Actual Bar configuration -------------------- */
      /** ------------------------------------------------------------------ */
      [`GanttStart${GanttNumber}`]: 'actualBarStartDate',
      [`GanttEnd${GanttNumber}`]: 'actualBarEndDate',
      [`GanttTop${GanttNumber}`]: 0,
      [`GanttHeight${GanttNumber}`]: 3,
      [`GanttClass${GanttNumber}`]: 'Gray',
    })
  }

  const columnsByName = {
    customers: customersColumn,
    activityNumber: activityNumberColumn,
    title: titleColumn,
    description: descriptionColumn,
    responsible: responsibleColumn,
    workspaces: workspacesColumn,
    suppliers: suppliersColumn,
    plannedStartDate: plannedStartDateColumn,
    plannedEndDate: plannedEndDateColumn,
    actualStartDate: actualStartDateColumn,
    actualBarStartDate: actualBarStartDateColumn,
    actualBarEndDate: actualBarEndDateColumn,
    status: statusColumn,
    state: stateColumn,
    statusDescription: statusDescriptionColumn,
    participants: participantsColumn,
    open: openColumn,
    ganttGanttHtmlRight: ganttGanttHtmlRightColumn,
    ganttBarColor: ganttBarColorColumn,
    links: linksColumn,
  }

  const makeRightCols = () => {
    const rightColumns = [] as any[]
    if (showGantt) {
      rightColumns.push(ganttColumn)
    }
    return rightColumns
  }

  const columns: TreeGridColumn[] = defaultColumnOrder.map((columnName) => columnsByName[columnName])
  return { columns: columns.concat({ Name: 'Panel', Visible: 0, CanHide: 0 }), rightColumns: makeRightCols() }
}

type MakeColumnProps = {
  options: Option[]
  defaultVisibleColumns?: ActivityColumnName[]
  defaultColumnOrder?: ActivityColumnName[]
  translations: TreeGridTranslations
  weekendDays: DayOfWeek[]
  timeZone: TimeZoneType
  dateFormat: string
  showGantt: boolean
}

function getDefaultColumnOrder(): ActivityColumnName[] {
  return [
    'open',
    'customers',
    'activityNumber',
    'title',
    'description',
    'responsible',
    'participants',
    'workspaces',
    'suppliers',
    'plannedStartDate',
    'plannedEndDate',
    'actualStartDate',
    'actualBarStartDate',
    'actualBarEndDate',
    'status',
    'state',
    'statusDescription',
    'links',
    'ganttGanttHtmlRight',
    'ganttBarColor',
  ]
}

function getDefaultVisibleColumns(): ActivityColumnName[] {
  return [
    'open',
    'customers',
    'activityNumber',
    'title',
    'responsible',
    'plannedStartDate',
    'plannedEndDate',
    'status',
    'state',
    'statusDescription',
  ]
}

type ActivityListColumn = TreeGridColumn & {
  Name: ActivityColumnName
}
