import { LinkResponse, LinkResponseSchema } from './link-response'

export const makeLinkViewModel = (linkResponse: LinkResponse): LinkViewModel => {
  try {
    const parsedResponse = LinkResponseSchema.parse(linkResponse)

    return {
      ...parsedResponse,
      anchorTag: getLinkAnchorTag(parsedResponse),
    }
  } catch (error) {
    throw new Error(`Failed to parse link: ${error}`)
  }
}

export type LinkViewModel = LinkResponse & {
  anchorTag: string
}

function getLinkAnchorTag(link: LinkResponse): string {
  return `<a href="${link.url}" target="_blank" rel="noopener noreferrer" >${link.title}</a>`
}
