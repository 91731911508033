import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { debounce } from 'lodash'
import Stack from '../../components/Stack'
import { useI18n } from '../../hooks'
import { z } from 'zod'

const UrlSchema = z
  .string()
  .url()
  .refine((url) => url.startsWith('http') || url.startsWith('https'), {
    message: 'URL must start with http or https',
  })

const useLinkForm = ({ link, isEditing, onAddLink, onUpdate }: LinkFormProps) => {
  const [formState, setFormState] = React.useState({ title: link.title, url: link.url })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateLink = React.useCallback(debounce(onUpdate, 500), [])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'url') onChangeUrl(e.target.value)
    if (e.target.name === 'title') onChangeTitle(e.target.value)
  }

  const onChangeTitle = (title: string) => {
    setFormState((prev) => ({ ...prev, title }))

    if (isEditing && link.id && title.length > 0) {
      debouncedUpdateLink(link.id, { title })
    }
  }

  const onChangeUrl = (url: string) => {
    setFormState((prev) => ({ ...prev, url }))

    if (isEditing && link.id) {
      const parseResult = UrlSchema.safeParse(url)
      if (parseResult.success) {
        debouncedUpdateLink(link.id, { url })
      }
    }
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onAddLink(formState)
  }

  const onFocusUrl = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget.selectionStart = event.currentTarget.value.length
    event.currentTarget.selectionEnd = event.currentTarget.value.length
  }

  return {
    formState,
    onChange,
    onSubmit,
    onFocusUrl,
  }
}

const LinkForm = (props: LinkFormProps) => {
  const translations = useTranslations()
  const {
    formState: { title, url },
    onChange,
    onSubmit,
    onFocusUrl,
  } = useLinkForm(props)

  const addLinkButton = (
    <Box sx={{ mt: 2 }}>
      <Button type="submit" variant="contained" color="primary">
        {translations.addLink}
      </Button>
    </Box>
  )

  return (
    <Box component="form" onSubmit={onSubmit} sx={{ py: 4 }}>
      <Stack spacing={3}>
        <TextField
          name="title"
          variant="outlined"
          label={translations.linkTitle}
          value={title}
          onChange={onChange}
          autoFocus
          fullWidth
        />
        <TextField
          name="url"
          variant="outlined"
          label={translations.linkAddress}
          value={url}
          onChange={onChange}
          onFocus={onFocusUrl}
          fullWidth
        />
        {props.isEditing ? null : addLinkButton}
      </Stack>
    </Box>
  )
}

export default LinkForm

const defaultTranslations = {
  linkTitle: 'Link title',
  linkAddress: 'Link address',
  addLink: 'Add link',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { linkTitle = defaults.linkTitle, linkAddress = defaults.linkAddress, addLink = defaults.addLink } = t || {}

  return {
    linkTitle,
    linkAddress,
    addLink,
  }
}

type Translations = typeof defaultTranslations
type LinkData = {
  id?: string
  title: string
  url: string
  entityId: string
  entityType: 'task' | 'project'
}

type LinkFormProps = {
  onAddLink: (linkData: { title: string; url: string }) => void | Promise<void>
  onUpdate: (linkId: string, updateData: { title?: string; url?: string }) => void | Promise<void>
  link: LinkData
  isEditing: boolean
}
